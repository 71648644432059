@import url('https://fonts.googleapis.com/css2?family=Dela+Gothic+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dela+Gothic+One&family=DotGothic16&display=swap');

#dela-gothic-one-regular {
  font-family: "Dela Gothic One", sans-serif;
  font-weight: 400;
  font-style: normal;
}
#dotgothic16-regular {
  font-family: "DotGothic16", sans-serif;
  font-weight: 400;
  font-style: normal;
}